var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.preloader)?_c('app-overlay-loader'):_c('form',{ref:"form",attrs:{"data-url":_vm.apiUrl.PAYROLL_SETTINGS},on:{"submit":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[_c('app-note',{staticClass:"mb-primary",attrs:{"title":_vm.$t('how_payrun_work'),"content-type":"html","notes":("<ol>\n                    <li>" + (_vm.$t('default_payrun_is_applicable_to_generate_payslip_for_all_employees_whenever_it_execute_from_payrun_module',{
                        payrun : ("<a href='" + (_vm.urlGenerator(_vm.apiUrl.PAYRUN_FRONTEND)) + "'>" + (_vm.$t('payrun')) + "</a>")
                    })) + "</li>\n                    <li>" + (_vm.$t('you_can_set_payrun_individually_over_the_default_from_the_employees_details',{
                        employees : ("<a href='" + (_vm.urlGenerator(_vm.apiUrl.EMPLOYEES_FRONTEND)) + "'>" + (_vm.$t('employees')) + "</a>")
                    })) + "</li>\n                 </ol>")}}),_vm._v(" "),_c('app-form-group',{attrs:{"type":"select","page":"page","label":_vm.$t('payrun_period'),"error-message":_vm.$errorMessage(_vm.errors, 'payrun_period'),"list":[
            {id:'', value:this.$fieldLabel('choose', 'payrun_period'), disabled: true},
            {id:'monthly', value:this.$t('monthly')},
            {id:'weekly', value:this.$t('weekly')}
         ]},model:{value:(_vm.formData.payrun_period),callback:function ($$v) {_vm.$set(_vm.formData, "payrun_period", $$v)},expression:"formData.payrun_period"}},[_c('template',{slot:"suggestion"},[(_vm.formData.payrun_period === 'monthly')?_c('small',{staticClass:"text-muted font-italic"},[_vm._v("\n                "+_vm._s(_vm.$t('always_run_for_the_last_month'))+"\n            ")]):_vm._e()])],2),_vm._v(" "),_c('app-form-group',{attrs:{"type":"radio","page":"page","label":_vm.$t('payrun_generating_type'),"radio-checkbox-name":"consider_type","error-message":_vm.$errorMessage(_vm.errors, 'consider_type'),"list":[
            {id:'hour', value:this.$t('hour')},
            {id:'daily_log', value:this.$t('daily_log')},
            {id:'none', value:this.$t('none')}
         ]},model:{value:(_vm.formData.consider_type),callback:function ($$v) {_vm.$set(_vm.formData, "consider_type", $$v)},expression:"formData.consider_type"}}),_vm._v(" "),(_vm.formData.consider_type === 'hour' || _vm.formData.consider_type === 'daily_log')?_c('div',{staticClass:"mt-primary"},[_c('app-form-group',{attrs:{"label-alignment":"","type":"switch","page":"page","label":this.$t('consider_overtime')},model:{value:(_vm.formData.consider_overtime),callback:function ($$v) {_vm.$set(_vm.formData, "consider_overtime", $$v)},expression:"formData.consider_overtime"}},[(_vm.formData.consider_overtime)?_c('template',{slot:"suggestion"},[_c('small',{staticClass:"mt-2 font-italic"},[_vm._v("\n                    ("+_vm._s(_vm.$t('overtime_will_be_calculated_after_the_end_of_the_total_schedule_time'))+")\n                ")])]):_vm._e()],2)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-3"}),_vm._v(" "),_c('div',{staticClass:"col-9"},[_c('app-submit-button',{attrs:{"label":_vm.$t('save'),"loading":_vm.loading},on:{"click":_vm.submitData}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }