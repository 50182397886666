var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.preloader)?_c('app-overlay-loader'):_c('form',{ref:"form",attrs:{"method":"post","data-url":this.apiUrl.GEOLOCATION_SETTINGS}},[_c('app-form-group',{attrs:{"label":_vm.$t('chose_geolocation_service'),"page":"page","type":"radio","radio-checkbox-wrapper":"row","label-alignment":"align-item-start","error-message":_vm.$errorMessage(_vm.errors, 'service_name'),"list":[{id:'google_map', value: 'Google Map'},{id:'mapbox', value: 'Mapbox'}, {id:'ip_geolocation', value: 'Ip Geolocation'}]},model:{value:(_vm.formData.service_name),callback:function ($$v) {_vm.$set(_vm.formData, "service_name", $$v)},expression:"formData.service_name"}}),_vm._v(" "),(_vm.formData.service_name === 'google_map')?_c('app-note',{staticClass:"mb-primary",attrs:{"title":_vm.$t('note'),"notes":[_vm.$t('about_google_map_geo_location_service', {
                      google_map: ("<a href='https://www.google.com/maps' target='_blank'>" + (_vm.$t('google_map')) + "</a>"),
                      website: ("<a href='https://console.cloud.google.com' target='_blank'>" + (_vm.$t('website')) + "</a>"),
                      })],"content-type":"html"}}):_vm._e(),_vm._v(" "),(_vm.formData.service_name === 'ip_geolocation')?_c('app-note',{staticClass:"mb-primary",attrs:{"title":_vm.$t('note'),"notes":[_vm.$t('about_geo_location_api', {
                      ip_geolocation: ("<a href='https://ipgeolocation.io/' target='_blank'>" + (_vm.$t('ip_geolocation')) + "</a>"),
                      website: ("<a href='https://ipgeolocation.io/signup.html' target='_blank'>" + (_vm.$t('website')) + "</a>"),
                      })],"content-type":"html"}}):_vm._e(),_vm._v(" "),(_vm.formData.service_name === 'mapbox')?_c('app-note',{staticClass:"mb-primary",attrs:{"title":_vm.$t('note'),"notes":[_vm.$t('about_mapbox_geo_location_service', {
                      mapbox: "<a href='https://www.mapbox.com/' target='_blank'>Mapbox</a>",
                      website: ("<a href='https://account.mapbox.com/auth/signup/' target='_blank'>" + (_vm.$t('website')) + "</a>"),
                      })],"content-type":"html"}}):_vm._e(),_vm._v(" "),_c('app-form-group',{attrs:{"page":"page","label":_vm.$t('api_key'),"type":"text","error-message":_vm.$errorMessage(_vm.errors, 'api_key')},model:{value:(_vm.formData.api_key),callback:function ($$v) {_vm.$set(_vm.formData, "api_key", $$v)},expression:"formData.api_key"}}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('app-submit-button',{attrs:{"label":_vm.$t('save'),"loading":_vm.loading},on:{"click":_vm.submitData}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }